
















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import GWysiwyg from '@/components/g/Wysiwyg.vue'

export default defineComponent({
  name: 'CessionAcquisitionPme',
  components: {},
  setup() {
    const components = {
      duoText: GWysiwyg,
    }

    return {
      ...useGetters(['content']),
      components,
    }
  },
})
